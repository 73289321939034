import React from "react";
import ImageZoom from "react-medium-image-zoom";

const logo = require("./assets/logo.png");
const screenGraph = require("./assets/graph.jpg");
const screenConsole = require("./assets/console.jpg");
const mac = require("./assets/mac.png");

const appVersion = "0.0.1-beta";
const baseUrl = "https://noooode-updates.s3.amazonaws.com/";

const downloadLinks = [
  {
    icon: mac,
    title: "Beta for macOS Download",
    link: `${baseUrl}Noooode-${appVersion}.dmg`
  }
];

function NavBar() {
  return (
    <div className="py-10 flex items-center justify-center">
      <img className="w-16 h-16 md:w-20 md:h-20" src={logo} alt="logo" />
      <div className="text-4xl md:text-6xl text-gray-800 font-bold ml-4">
        NO<span className="text-green-500">◉</span>
        <span className="text-orange-500">◉</span>
        <span className="text-purple-500">◉</span>DE
      </div>
      <span className="text-sm text-gray-500 self-start mt-4 ml-2">
        {appVersion}
      </span>
    </div>
  );
}

function Link({ data }) {
  return (
    <div className="flex flex-col items-center">
      <a
        className="cursor-pointer hover:underline flex md:flex-row items-center justify-center"
        href={data.link}
        target="_blank"
        rel="noopener noreferrer">
        <img className="w-1/3 md:w-1/4" src={data.icon} alt={data.title} />
        <div className="ml-4 text-gray-600 font-bold">{data.title}</div>
      </a>
    </div>
  );
}

function Image({ src, alt }) {
  return <ImageZoom image={{ src, alt }} zoomImage={{ src, alt }} />;
}

function H2({ className, children }) {
  return (
    <div
      className={[
        "mt-24 mb-10 text-5xl md:text-6xl font-bold text-green-500 text-center leading-tight",
        className
      ].join(" ")}>
      {children}
    </div>
  );
}

function Home() {
  return (
    <div className="min-h-screen pb-32">
      <NavBar />
      <div className="m-10 text-center">
        <p className="text-3xl md:text-5xl font-bold text-gray-800 leading-normal">
          Node-based Visualization Scripting Tool.
        </p>
        <div className="mt-24 text-gray-500">
          <span className="font-bold">Noooode</span> is in development. Download
          <span className="font-bold"> Beta</span> today.
        </div>
        <div className="flex justify-center mt-10">
          {downloadLinks.map(it => (
            <Link key={it.title} data={it} />
          ))}
        </div>
      </div>
      <div className="md:w-4/5 mx-auto">
        <H2 className="text-green-500">
          V<span className="font-normal text-4xl md:text-5xl">isual, </span>I
          <span className="font-normal text-4xl md:text-5xl">nteresting </span>
          Editor
        </H2>
        <Image src={screenGraph} alt="screen_graph" />
      </div>
      <div className="md:w-4/5 mx-auto">
        <H2 className="text-indigo-500">
          S<span className="font-normal text-4xl md:text-5xl">imple, </span>E
          <span className="font-normal text-4xl md:text-5xl">fficient </span>
          Console
        </H2>
        <Image src={screenConsole} alt="screen_console" />
      </div>
    </div>
  );
}

export default Home;
